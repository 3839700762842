import React from "react"
import "./Card.css"
import classNames from "classnames"

const Card = props => {
  let ClassCards = classNames("Card", props.typecard)
  return (
    <div className={ClassCards}>
      <img alt="" src={props.image} />
      <h4>{props.title}</h4>
      <p>{props.text}</p>
    </div>
  )
}

export default Card
