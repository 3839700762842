import React from "react"
import Card from "../components/Card"
import Section from "../components/Section"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionLastProject from "../components/SectionLastProject"
import Hero from "../components/Hero"
// images 
import logo from '../images/logoverpar.svg';
import pelton from '../images/Pelton.svg';
import bomba from '../images/Bomba.svg';
import ambiental from '../images/Ambiental.svg';
import simulacion from '../images/Simulacion.svg';
import presas from '../images/Presas.svg';
import software from '../images/Software.svg';
import client1 from '../images/client1.png';
import client2 from '../images/client2.png';
import client3 from '../images/client3.png';
import client4 from '../images/client4.png';
import client5 from '../images/client5.jpeg';
import client6 from '../images/client6.jpg';
import client7 from '../images/client7.png';
import client8 from '../images/client8.png';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="Hero">
      <div className="HeroUnd">
        <div className="HeroGroup">
          <img alt="" src={logo} width="200" />
          <h1>VERPAR</h1>
          <h3>Consultoria / Investigación / Supervisión</h3>
          <p>
            Contamos con profesionales capacitados para brindar un servicio de
            calidad y profesionalismo. Proporcionando un soporte técnico de
            acuerdo a estándares internacionales.
          </p>
        </div>
      </div>
    </div>
    <div className="Cards">
      <h3>Servicios que ofrecemos</h3>
      <p style={{ color: "#9da6b8" }}>
        Contamos con una amplia experiencia en diferentes áreas de consultoría.{" "}
      </p>
      <div className="CardGroup">
        <Card
          typecard="Energy"
          title="Plantas de Energía Renovables"
          text="Evaluación y análisis de proyectos hidroeléctricos, solar y eólico."
          image={pelton}
        />
        <Card
          typecard="Bombplant"
          title="Plantas de Bombeo"
          text="Proyectos y evaluación de plantas de bombeo de gran envergadura"
          image={bomba}
        />
        <Card
          typecard="Environment"
          title="Evaluación Ambiental"
          text="Estudios y monitoreo ambiental."
          image={ambiental}
        />
        <Card
          typecard="Simulation"
          title="Simulación"
          text="Análisis del comportamiento mecánico e hidráulico por metodos computacionales."
          image={simulacion}
        />
        <Card
          typecard="Siphons"
          title="Presas y Sifones"
          text="Diseño de sistemas hidromecánicos de presas y sifones de mediana y gran envergadura."
          image={presas}
        />
        <Card
          typecard="Software"
          title="Desarrollo de Software"
          text="Análisis y desarrollo de aplicaciones que permita optimizar procesos."
          image={software}
        />
      </div>
    </div>
    <Section
      title="Nuestros clientes"
      text="Nuestra experiencia y capacidad es resaltada a travez de los clientes con los que trabajamos y puede ser demostrado en los proyectos desarrollados."
      client1={client1}
      client2={client2}
      client3={client3}
      client4={client4}
      client5={client5}
      client6={client6}
      client7={client7}
      client8={client8}
    />
    <SectionLastProject
      title="Ultimos proyectos"
      caption="La experiencias es respaldada por los proyectos realizados por VERPAR."
    />
  </Layout>
)

export default IndexPage
