import React from "react"
import "./Section.css"

const Section = props => (
  <div className="SectionGroup">
    <div className="SectionTitleGroup">
      <div className="SectionInfoGroup">
        <h3 className="SectionTitle">{props.title}</h3>
        <p className="SectionText">{props.text}</p>
      </div>
      <div className="SectionClientGroup">
        <img alt="" className="SectionClient" src={props.client1} />
        <img alt="" className="SectionClient" src={props.client2} />
        <img alt="" className="SectionClient" src={props.client3} />
        <img alt="" className="SectionClient" src={props.client4} />
        <img alt="" className="SectionClient" src={props.client5} />
        <img alt="" className="SectionClient" src={props.client6} />
        <img alt="" className="SectionClient" src={props.client7} />
        <img alt="" className="SectionClient" src={props.client8} />
      </div>
    </div>
  </div>
)

export default Section
