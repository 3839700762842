import React from "react"
import "./SectionLastProject.css"
import Cell from "../components/Cell"
import staticdata from "../../staticdata.json"
import { Link } from "gatsby"

const SectionLastProject = props => (
  <div>
    <h3 className="SectionLastProjectTitle">{props.title}</h3>
    <p className="SectionCaption">{props.caption}</p>
    <div className="SectionCellGroup">
      <Cell
        title={staticdata.cells[0].title}
        client={staticdata.cells[0].client}
      />
      <Cell
        title={staticdata.cells[1].title}
        client={staticdata.cells[1].client}
      />
      <Cell
        title={staticdata.cells[2].title}
        client={staticdata.cells[2].client}
      />
    </div>
    <div className="SectionMore">
      <Link to="/projects">
        {/* <ButtonStyle title="Ver más" /> */}
        <button className="ButtonSpecialItem">Ver más</button>
      </Link>
    </div>
  </div>
)
export default SectionLastProject
