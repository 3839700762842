import React from "react"
import "./Cell.css"

const Cell = props => (
  <div className="CellItem">
    <h4 className="CellTitle">{props.title}</h4>
    <p className="CellClient">{props.client}</p>
  </div>
)

export default Cell
